import {
  ActionIcon,
  Anchor,
  Box,
  Burger,
  Button,
  Container,
  createStyles,
  Divider,
  Drawer,
  Group,
  Header,
  MantineTheme,
  Menu,
  ScrollArea,
  Select,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import Link from 'next/link';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import { Moon } from '@emotion-icons/ionicons-outline/Moon';
import { Sunny } from '@emotion-icons/ionicons-outline/Sunny';
import { useRouter } from 'next/router';
import React, { memo, ReactElement, useCallback, useRef } from 'react';
import { useLogout, useSession } from '../../hooks/useSession';
import justinHead from '../../../public/images/justin-head.png';
import Image from 'next/image';
import FullLogo from '../Logos/FullLogo';
import { usePolicyholdersNamesQuery } from '../../graphql/operations/policyholders.generated';
import { Search } from '@emotion-icons/ionicons-outline/Search';
import { IS_PREVIEW, IS_PRODUCTION, IS_AUDIT } from '../../constants';
import trackEvent from '../../utils/trackEvent';
import { useSpotlight } from '@mantine/spotlight';
import { MessageSquareDetail } from '@emotion-icons/boxicons-regular/MessageSquareDetail';

const getNavColor = (theme: MantineTheme) => {
  if (IS_PREVIEW) {
    return theme.colors.green[7];
  }
  if (IS_PRODUCTION) {
    return theme.colorScheme === 'light' ? theme.white : theme.black;
  }

  return theme.colors.cyan[7];
};

const Navbar = ({ forInsured = false }: { forInsured?: boolean }) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const router = useRouter();
  const { session } = useSession();
  const logout = useLogout();
  const { data } = usePolicyholdersNamesQuery({
    skip: !session?.user,
  });
  const orgLogoUrl = session?.organization?.logoUrl;

  const onSearchChange = useCallback(
    (value) => {
      router.push(`/u/policyholders/${value}`);
    },
    [router]
  );
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const spotlight = useSpotlight();
  const toggleShepsyAI = () => spotlight.openSpotlight();

  useHotkeys([['mod+K', () => searchRef.current?.focus()]]);

  return (
    <Header
      height={60}
      fixed={false}
      styles={(theme) => ({
        root: {
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          background: getNavColor(theme),
          zIndex: 100,
          display: 'flex',
        },
      })}
    >
      <div style={{ flex: '1' }}>
        <Link legacyBehavior href={'/u'} passHref>
          <a
            className="logo"
            style={{
              float: 'left',
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
              paddingRight: 12,
            }}
          >
            {!IS_AUDIT &&
            (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) === 'preview' ? (
              <>
                <Tooltip label="Hype Hype Hype" position="right">
                  <Box
                    className="d-flex align-items-center"
                    sx={{
                      '&:hover': {
                        transform: 'scaleX(-1)',
                      },
                    }}
                  >
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>
                <FullLogo />
              </>
            )}
            {orgLogoUrl ? (
              <div
                style={{
                  height: 64,
                  padding: '16px 0px',
                }}
                className="d-flex align-items-center"
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={orgLogoUrl} height="100%" alt="Organization logo" />
              </div>
            ) : null}
          </a>
        </Link>
      </div>

      {forInsured ? (
        <>
          <div style={{ flex: '1', justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
            <Button component="a" href="mailto:support@withshepherd.com">
              Need help?
            </Button>
          </div>
        </>
      ) : (
        <div style={{ flex: '1', justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
          {session?.user?.id && (
            <Group spacing={16}>
              <ActionIcon
                style={{ visibility: IS_PRODUCTION ? 'hidden' : 'visible' }}
                onClick={() => toggleShepsyAI()}
                size="md"
              >
                <MessageSquareDetail size={18} color="black" />
              </ActionIcon>
              <Select
                ref={searchRef}
                searchable
                onChange={onSearchChange}
                placeholder="Search"
                size="xs"
                maxDropdownHeight={280}
                icon={<Search size={14} />}
                rightSection={<span />}
                data={
                  data?.policyholdersNames?.map((policyholder) => ({
                    label: `${policyholder.name}${policyholder.deletedAt ? ' (archived)' : ''}`,
                    value: policyholder.id,
                  })) || []
                }
              />

              <>
                <ActionIcon
                  onClick={() => toggleColorScheme()}
                  size="md"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    color:
                      theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[6],
                  })}
                >
                  {colorScheme === 'dark' ? <Sunny size={18} /> : <Moon size={18} />}
                </ActionIcon>
                <Menu trigger="hover">
                  <Menu.Target>
                    {
                      <Anchor>
                        {`${session?.user?.name || 'User'} ${
                          session?.organization?.name ? `(${session?.organization?.name})` : ''
                        }`}{' '}
                      </Anchor>
                    }
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item key="companySettings" onClick={() => router.push('/settings')}>
                      Settings
                    </Menu.Item>

                    <Menu.Item key="logout" onClick={() => logout()}>
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            </Group>
          )}
        </div>
      )}
    </Header>
  );
};

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    borderBottom: '2px solid transparent',

    [theme.fn.smallerThan('sm')]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },
  linkActive: {
    borderBottom: `2px solid black`,
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} ${theme.spacing.md}`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
}));

export function PublicHeader({
  fluid = false,
  alwaysShowBorder = false,
  fixed = true,
  navLinks = [],
  rightLinks = [
    <Button
      variant="white"
      component="a"
      key="help"
      href="mailto:support@withshepherd.com"
      onClick={() => {
        trackEvent('Clicked Help', { category: 'Navbar' });
      }}
    >
      Need help?
    </Button>,
    <Link href="/signup" passHref legacyBehavior key="signup">
      <Button
        component="a"
        onClick={() =>
          trackEvent('Clicked Get Appointed', {
            category: 'Navbar',
          })
        }
      >
        Get Appointed
      </Button>
    </Link>,
  ],
}: {
  fluid?: boolean;
  alwaysShowBorder?: boolean;
  fixed?: boolean;
  navLinks?: { label: string; href: string }[];
  rightLinks?: ReactElement[];
}) {
  const router = useRouter();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  const { classes, theme, cx } = useStyles();

  return (
    <>
      <Header
        height={{ base: 60, md: 68 }}
        display="flex"
        withBorder={alwaysShowBorder}
        zIndex={199}
        fixed={fixed}
      >
        <Container size="xl" fluid={fluid} sx={{ flex: 1 }}>
          <Group position="apart" sx={{ height: '100%' }}>
            <Link href="/" legacyBehavior>
              <FullLogo />
            </Link>

            <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
              {navLinks.map((navItem) => (
                <Link key={navItem.label} href={navItem.href} legacyBehavior>
                  <Anchor
                    className={cx(classes.link, {
                      [classes.linkActive]: router.pathname === navItem.href,
                    })}
                  >
                    {navItem.label}
                  </Anchor>
                </Link>
              ))}
              {router?.isPreview && (
                <Anchor key="preview" href="/api/clearPreview" className={cx(classes.link)}>
                  Exit preview mode
                </Anchor>
              )}
            </Group>

            <Group className={classes.hiddenMobile}>{rightLinks}</Group>

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Container>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
          {navLinks.map((navItem) => (
            <Link key={navItem.label} href={navItem.href} legacyBehavior onClick={closeDrawer}>
              <Anchor className={classes.link}>{navItem.label}</Anchor>
            </Link>
          ))}

          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

          <Group position="center" grow pb="xl" px="md">
            {rightLinks}
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}

export default memo(Navbar);
