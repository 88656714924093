import React, { ReactNode } from 'react';
import { AppShell as MantineAppShell, AppShellProps, Button, Loader, Text } from '@mantine/core';
import Navbar from './Navbar';
import { useSession } from '../../hooks/useSession';
import { UserRole } from '../../graphql/types.generated';
import Link from 'next/link';

type MainAppShellProps = {
  children: ReactNode;
  withAuth?: boolean;
  adminOnly?: boolean;
  loading?: boolean;
  navbar?: AppShellProps['navbar'];
  header?: AppShellProps['header'];
  aside?: AppShellProps['aside'];
  fixed?: boolean;
};

const MainAppShell = ({
  children,
  withAuth,
  adminOnly,
  navbar,
  fixed = false,
}: MainAppShellProps) => {
  const { session, loading: loadingSession } = useSession();
  const showLoader = (withAuth || adminOnly) && (typeof window === 'undefined' || loadingSession);
  let childrenToShow = null;

  if (withAuth && !loadingSession && !session) {
    childrenToShow = <div>You are not authenticated</div>;
  }

  if (adminOnly && session?.user?.role !== UserRole.Admin) {
    childrenToShow = (
      <div>
        <Text>You are not authenticated 😿</Text>
        <Link legacyBehavior href="/login" passHref>
          <Button mt="md">Login</Button>
        </Link>
      </div>
    );
  }

  if (showLoader) {
    childrenToShow = <Loader />;
  }

  return (
    <MantineAppShell
      padding="md"
      fixed={fixed}
      header={<Navbar />}
      navbar={navbar}
      styles={(theme) => ({
        main: {
          minHeight: '100vh',
          paddingBottom: 64,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
      })}
    >
      {childrenToShow || children}
    </MantineAppShell>
  );
};

export const PublicAppShell = ({ children, navbar, header, aside }: MainAppShellProps) => {
  return (
    <MantineAppShell
      padding="md"
      header={header}
      navbar={navbar}
      aside={aside}
      asideOffsetBreakpoint="sm"
      styles={(theme) => ({
        main: {
          minHeight: '100vh',

          paddingBottom: 64,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
      })}
    >
      {children}
    </MantineAppShell>
  );
};

export default MainAppShell;
